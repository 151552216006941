<div class="flex flex-col max-w-[600px] md:min-w-[500px] max-h-[300px] -m-6">
  <!-- Header -->
  <div
    class="flex flex-0 items-center justify-between h-12 pr-3 sm:pr-5 pl-6 sm:pl-8 mt-4">
    <div class="text-16-semi">{{'START_BRAINSTORM' | translate}}</div>
    <button mat-icon-button [tabIndex]="-1" (click)="this.matDialogRef.close()">
      <mat-icon
        class="text-current icon-color"
        [svgIcon]="'heroicons_outline:x'"></mat-icon>
    </button>
  </div>
  <!-- Compose form -->
  <form
    class="flex flex-col flex-auto p-2 sm:p-4 overflow-y-auto justify-center"
    [formGroup]="composeForm">
    <!-- To -->
    <div class="flex justify-start mt-5 px-8">
      <span>{{'BRAINSTORM_COLOR_CHOICE' | translate}}</span>
    </div>
    <div class="flex justify-between mt-5 px-8">
      <div>
        <mat-checkbox
          [color]="'primary'"
          [formControlName]="'checkboxAllColors'">
          {{'ALL_COLORS' | translate}}
        </mat-checkbox>
      </div>

      <div>
        <button type="button" class="inline-flex w-full justify-center items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 max-h-[36px]"
        id="menu-button"
        aria-expanded="true"
        aria-haspopup="true"
        [matMenuTriggerFor]="labelsMenu">
      {{'COLORS' | translate}}
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
        <mat-menu #labelsMenu="matMenu" [overlapTrigger]="false">
          <animator-category-selector
            [boardId]="this.data.boardId"
            (colorValues)="colorChanged($event)"></animator-category-selector>
        </mat-menu>
      </div>
    </div>
    <!-- Body -->
  </form>
  <!-- Actions -->
  <div
    class="flex justify-end p-4 sm:flex-row sm:items-center sm:mt-4">
    <div class="flex items-center sm:mt-0">
      <!-- Discard -->
      <button
        class="ml-auto sm:ml-0 btn-tertiary-hover max-h-[36px] flex items-center"
        (click)="this.matDialogRef.close()">
        {{'CANCEL' | translate}}
      </button>
      <!-- Save as draft -->
      <button (click)="startBrainstorm()" class="sm:mx-3 btn-primary-hover max-h-[36px] flex items-center">
        <span>{{'START_BRAINSTORM' | translate}}</span>
      </button>
    </div>
  </div>
</div>
