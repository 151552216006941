import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { ProjectService } from '@excelway/services/project.service';
import { TemplateService } from '@excelway/services/template.service';
import {
  Availability,
  FlatNode,
  ProjectNode,
  UpdatedAvailability,
} from '@excelway/types/create-object-template.types';
import { Project } from 'app/modules/projects/project-settings/models/project';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'template-availability',
  templateUrl: './availability.component.html',
})
export class TemplateAvailabilityComponent implements OnInit {
  projects: Project[];
  availability$: Observable<Availability>;
  projectSelectionForm = new FormGroup({
    projectSelection: new FormControl(''),
  });

  treeControl = new FlatTreeControl<FlatNode>(
    node => node.level,
    node => node.expandable
  );

  private _transformer = (node: ProjectNode, level: number): FlatNode => {
    return {
      expandable: !!node.Project && node.Project.length > 0,
      name: node.name,
      level: level,
      id: node.id,
      Project: node.Project,
    };
  };

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.Project
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  checklistSelection = new SelectionModel<FlatNode>(true /* multiple */);

  getLevel = (node: FlatNode): any => node.level;

  hasNoContent = (_: number, _nodeData: any): any => _nodeData.text === '';

  hasChild = (_: number, node: FlatNode): any => node.expandable;

  constructor(
    private _projectService: ProjectService,
    private _templateService: TemplateService,
    @Inject(MAT_DIALOG_DATA)
    public data
  ) {}

  ngOnInit(): void {
    this.getProjects();
  }

  getProjects(): void {
    this._projectService.getprojectsAndchildren().subscribe(response => {
      this.projects = response.Project;
      this.dataSource.data = this.projects;

      this.availability$ = this._templateService
        .getTemplateAvailability(this.data.id)
        .pipe(
          map((availability: Availability) => {
            if (availability.availableAll) {
              this.projectSelectionForm
                .get('projectSelection')
                ?.setValue('allProjects');
            } else {
              this.projectSelectionForm
                .get('projectSelection')
                ?.setValue('someProjects');
              this.selectNodesBasedOnAvailability(availability);
            }
            return availability;
          })
        );
    });
  }

  onSubmit(): void {
    const selectedValue =
      this.projectSelectionForm.get('projectSelection')!.value;
    let availabilityData: UpdatedAvailability;

    if (selectedValue === 'allProjects') {
      availabilityData = {
        availableAll: true,
        availableInProjects: [],
      };
    } else {
      const selectedProjects = this.getSelectedProjects();
      availabilityData = {
        availableAll: false,
        availableInProjects: selectedProjects,
      };
    }

    // If "someProjects" is selected and no project is selected, prevent form submission
    if (
      selectedValue === 'someProjects' &&
      availabilityData.availableInProjects.length === 0
    ) {
      return;
    }

    this._templateService
      .updateTemplateAvailability(this.data.id, availabilityData)
      .subscribe(
        response => {
          return response;
        },
        error => {
          console.error('Error updating template availability:', error);
        }
      );
  }

  areProjectsSelected(): boolean {
    const selectedValue =
      this.projectSelectionForm.get('projectSelection')!.value;
    return (
      selectedValue === 'allProjects' || this.getSelectedProjects().length > 0
    );
  }
  getSelectedProjects(): string[] {
    const selectedProjects = this.treeControl.dataNodes
      .filter(node => this.checklistSelection.isSelected(node))
      .map(node => node.id);
    return selectedProjects;
  }
  selectNodesBasedOnAvailability(availability: Availability): void {
    if (Array.isArray(availability.availableInProjects)) {
      availability.availableInProjects.forEach(projectId => {
        const node = this.treeControl.dataNodes.find(
          node => node.id === projectId
        );
        if (node) {
          this.checklistSelection.select(node);
          this.checkAllParentsSelection(node);
        }
      });
    }
  }

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: FlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: FlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child =>
      this.checklistSelection.isSelected(child)
    );
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: FlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.every(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }
  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: FlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: FlatNode): void {
    let parent: FlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: FlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: any): FlatNode | null {
    const currentLevel = this.getLevel(node);
    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }
}
