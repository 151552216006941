<div class="flex flex-col w-[450px] -m-6">
  <!-- Header -->
  <div
    class="flex flex-0 items-center justify-between h-10 pr-3 sm:pr-5 pl-6 sm:pl-8 mt-4">
    <div class="text-16-semi">{{'START_VOTING' | translate}}</div>
    <button mat-icon-button [tabIndex]="-1" (click)="this.matDialogRef.close()">
      <mat-icon
        class="text-current icon-color"
        [svgIcon]="'heroicons_outline:x'"></mat-icon>
    </button>
  </div>

  <!-- Compose form -->
  <form
    class="flex flex-col flex-auto sm:p-4 overflow-y-auto sm:px-8"
    [formGroup]="composeForm">
    <!-- To -->
    <div class="flex justify-between mt-3">
      <div>
        <mat-checkbox
          [color]="'primary'"
          [formControlName]="'checkboxAllColors'">
          {{'ALL_COLORS' | translate}}
        </mat-checkbox>
      </div>

      <div>
        <button type="button" class="inline-flex w-full justify-center items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 max-h-[36px]"
        id="menu-button"
        aria-expanded="true"
        aria-haspopup="true"
        [matMenuTriggerFor]="labelsMenu">
      {{'COLORS' | translate}}
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
        <mat-menu #labelsMenu="matMenu" [overlapTrigger]="false">
          <animator-category-selector></animator-category-selector>
        </mat-menu>
      </div>
    </div>

    <mat-form-field class="w-full mt-2">
      <mat-label class="text-14-med-900"> {{'VOTES_PER_PARTICIPANTS' | translate}} </mat-label>
      <input
        matInput
        [formControlName]="'votesParticipants'"
        type="number"
        max="200"
        required />
    </mat-form-field>

    <mat-form-field class="w-full">
      <mat-label class="text-14-med-900"> {{'MAX_PER_CARD' | translate}} </mat-label>
      <input
        matInput
        [formControlName]="'maxPerCard'"
        type="number"
        max="15"
        required />
    </mat-form-field>
    <!-- Body -->
  </form>

  <!-- Actions -->
  <div
    class="flex justify-end p-4 py-2 sm:flex-row sm:items-center sm:mt-2 mb-4">
    <div class="flex items-center sm:mt-0">
      <!-- Discard -->
      <button
        class="ml-auto sm:ml-0 btn-tertiary-hover max-h-[36px] flex items-center"
        (click)="this.matDialogRef.close()">
        {{'CANCEL' | translate}}
      </button>
      <!-- Save as draft -->
      <button (click)="startVoting()" class="sm:mx-3 btn-primary-hover max-h-[36px] flex items-center">
        <span> {{'START_VOTING' | translate}} </span>
      </button>
    </div>
  </div>
</div>
