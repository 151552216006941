import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private _httpClient: HttpClient) {}

  // Add member
  addMember(memberdata: any, roleTypeObjectId: string): Observable<any> {
    const roleTypeObject = 'Project';
    return this._httpClient.patch(
      `${environment.backendUrl}/v1/${roleTypeObject}/${roleTypeObjectId}/add_member`,
      memberdata,
      { withCredentials: true }
    );
  }

  // Update member
  updateMember(memberdata: any, roleTypeObjectId: string): Observable<any> {
    const roleTypeObject = 'Project';
    return this._httpClient.patch(
      `${environment.backendUrl}/v1/${roleTypeObject}/${roleTypeObjectId}/edit_member`,
      memberdata,
      { withCredentials: true }
    );
  }

  // Remove member
  removeMember(memberdata: any, roleTypeObjectId: string): Observable<any> {
    const roleTypeObject = 'Project';
    return this._httpClient.patch(
      `${environment.backendUrl}/v1/${roleTypeObject}/${roleTypeObjectId}/remove_member`,
      memberdata,
      { withCredentials: true }
    );
  }

  removeCardMember(cardId: string, memberId: string): Observable<any> {
    const payload = {
      roleType: 'Card',
      roleTypeId: cardId,
      memberId: { memberId },
    };
    return this._httpClient.patch<any>(
      `${environment.backendUrl}/v1/toggle/${payload.roleType}/${payload.roleTypeId}/member`,
      payload.memberId,
      { withCredentials: true }
    );
  }

  // Get members
  getMembersProject(objectId: string, roleType: string): Observable<any> {
    return this._httpClient.get(
      `${environment.backendUrl}/v1/${roleType}/${objectId}/users`,
      { withCredentials: true }
    );
  }
}
