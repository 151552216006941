import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthzService {
  // Set user ID in the service
  private userIdSubject: BehaviorSubject<string | null> = new BehaviorSubject<
    string | null
  >(null);
  userId$: Observable<string | null> = this.userIdSubject.asObservable();

  setUserId(userId: string): void {
    this.userIdSubject.next(userId);
  }

  getUserId(): string | null {
    return this.userIdSubject.value;
  }

  private roleSubject: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );
  role$: Observable<string> = this.roleSubject.asObservable();

  constructor(private _httpClient: HttpClient) {}
  setRole(role: string): void {
    this.roleSubject.next(role);
  }

  getRole(): string {
    return this.roleSubject.value;
  }

  fetchUserRole(objectId: string): Observable<{ role: string }> {
    const userId = this.getUserId();
    return this._httpClient.get<{ role: string }>(
      `${environment.backendUrl}/v1/can-access/${objectId}/${userId}`,
      { withCredentials: true }
    );
  }
}
