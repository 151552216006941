import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { AuthzService } from '@excelway/services/authz.service';
import { TranslationService } from '@excelway/services/translation.service';
import { Field } from 'app/modules/user-interface/user-interface.store';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-project-settings',
  templateUrl: './project-settings.component.html',
  styleUrls: ['./project-settings.component.scss'],
})
export class ProjectSettingsComponent implements OnInit {
  interfaceId = 'cm1p5fzvz001ug0btypumbusa';
  fields: Field[] = [];
  @ViewChild('drawer') drawer: MatDrawer;
  drawerMode: 'over' | 'side' = 'side';
  drawerOpened: boolean = true;
  panels: any[] = [
    {
      id: 'object-details',
      icon: 'heroicons_outline:information-circle',
      title: 'PROJECT_DETAILS_TITLE',
      description: 'MANAGE_SPACE_INFO_AND_ACTIONS',
    },
    {
      id: 'access-invites',
      icon: 'heroicons_outline:user-group',
      title: 'ACCESS_AND_INVITATIONS',
      description: 'CONTROL_SPACE_ACCESS',
    },
  ];
  selectedPanel: string = 'object-details';
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  role: string;

  /**
   * Constructor
   */
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data,
    private _changeDetectorRef: ChangeDetectorRef,
    public _matDialogRef: MatDialogRef<ProjectSettingsComponent>,
    private _authzService: AuthzService,
    private _translationService: TranslationService
  ) {
    // Initialize the selected panel based on the provided data
    if (data.tapToOpen) {
      this.selectedPanel = this.data.tabToOpen;
    } else {
      this.selectedPanel = data.selectedPanel || 'object-details';
    }
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Navigate to the panel
   *
   * @param panel
   */

  ngOnInit(): void {
    this._translationService
      .loadComponentTranslations(this.interfaceId)
      .subscribe(fields => {
        this.fields = fields;
        this._changeDetectorRef.detectChanges();
      });
    this.getUserRole();
    this._changeDetectorRef.detectChanges();
  }

  getUserRole(): void {
    this._authzService.fetchUserRole(this.data.id).subscribe(response => {
      this.role = response.role;
      this._authzService.setRole(this.role);
      this._changeDetectorRef.detectChanges();
    });
  }

  goToPanel(panel: string): void {
    this.selectedPanel = panel;

    // Close the drawer on 'over' mode
    if (this.drawerMode === 'over') {
      this.drawer.close();
    }
  }

  /**
   * Get the details of the panel
   *
   * @param id
   */
  getPanelInfo(id: string): any {
    console.log(this.panels.find(panel => panel.id === id));
    return this.panels.find(panel => panel.id === id);
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}
