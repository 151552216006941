import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CreatedChildObject } from '@excelway/models/api/created-child-object';
import { UpdatePayload } from '@excelway/models/api/update-payload';
import {
  CardModelResponse,
  SubCardModelResponse,
  Tag,
} from '@excelway/models/board/card.model';
import { ReceivedEvent } from '@excelway/models/socket-io/event';
import { UserModel } from '@excelway/models/user/user.model';
import { CardService } from '@excelway/services/card.service';
import { ObjectService } from '@excelway/services/object.service';
import { SocketService } from '@excelway/services/socket-io.service';
import { UserService } from '@excelway/services/user.service';
import { AuthUser } from '@excelway/types/auth-user.types';
import {
  ComponentStore,
  OnStateInit,
  tapResponse,
} from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { AuthStoreSelectors } from 'app/store/auth';
import {
  EMPTY,
  Observable,
  catchError,
  from,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
interface CardState {
  card: CardModelResponse;
  isParent: boolean;
  parentId?: string;
  parentName?: string;
}
export const initialState: CardState = {
  card: {
    members: [],
  } as any,
  isParent: false,
};
@Injectable()
export class CardStore
  extends ComponentStore<CardState>
  implements OnStateInit, OnDestroy
{
  params = this._route.snapshot.params;
  roomId: string;
  constructor(
    private readonly _store: Store,
    private readonly _objectService: ObjectService,
    private readonly _socketService: SocketService,
    private readonly _cardService: CardService,
    private readonly _route: ActivatedRoute,
    private readonly _usersService: UserService
  ) {
    super(initialState);
    this.roomId = this.params.cardId;
  }
  ngrxOnStateInit = (): void => {
    this.joinRoom(this.roomId);
  };
  //**************************************************effects***************************************
  readonly joinRoom = this.effect((roomId$: Observable<string>) => {
    return roomId$.pipe(
      withLatestFrom(
        // Logger user
        this._store.select(AuthStoreSelectors.selectLoggedUser)
      ),
      tapResponse(
        ([roomId, user]: [string, AuthUser]) => {
          if (roomId) this._socketService.joinRoom(roomId, user);
        },
        error => console.error(error)
      )
    );
  });
  readonly leaveRoom = this.effect<void>(source$ =>
    source$.pipe(
      withLatestFrom(
        this.select(state => state.card?.id),
        this._store.select(AuthStoreSelectors.selectLoggedUser)
      ),
      tapResponse(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ([_, roomId, user]: [void, string, AuthUser]) => {
          if (roomId) this._socketService.leaveRoom(roomId, user);
        },
        error => console.error(error)
      )
    )
  );
  readonly getCard = this.effect(
    (
      card$: Observable<{
        id: string;
        isParent: boolean;
        parentName?: string;
        parentId?: string;
      }>
    ) => {
      return card$.pipe(
        switchMap(({ id, isParent, parentName, parentId }) =>
          from(
            this.getCardPropertiesAsPromise(id, isParent, parentName, parentId)
          )
        )
      );
    }
  );

  private getCardPropertiesAsPromise(
    id: string,
    isParent: boolean,
    parentName?: string,
    parentId?: string
  ): Promise<void> {
    return this._cardService
      .getCardProperties(id)
      .pipe(
        tap({
          next: card => {
            this.setCard({ card, isParent, parentName, parentId });
          },
        }),
        catchError(() => EMPTY)
      )
      .toPromise();
  }
  readonly deleteChecklist = this.effect(
    (params$: Observable<{ id: string; boardId: string }>) => {
      return params$.pipe(
        switchMap(({ id, boardId }) => {
          return this._objectService.deleteObject('Checklist', id).pipe(
            tap(() => {
              this.removeChecklist(id);
              this._socketService.publishEvent({
                roomId: boardId,
                eventType: 'remove-checklist',
                payload: {
                  id,
                },
              });
            }),
            catchError(() => {
              return EMPTY;
            })
          );
        })
      );
    }
  );
  readonly updateCard = this.effect(
    (
      card$: Observable<{
        cardId: string;
        roleType: string;
        body: any; // Ensure body can include priority and other attributes
        boardId: string;
      }>
    ) => {
      return card$.pipe(
        switchMap(({ cardId, roleType, body, boardId }) =>
          this._cardService
            .updateCard({
              objectId: cardId,
              roleType,
              body,
            })
            .pipe(
              tap(updatedCard => {
                this.setUpdatedCard(updatedCard);

                // Extract priority from the body and ensure it's included in the update
                const { startDate, endDate, name, description, isCompleted } =
                  body;
                this._socketService.publishEvent({
                  roomId: boardId,
                  eventType: 'update-card-details',
                  payload: {
                    cardId,
                    ...(startDate && { startDate }),
                    ...(endDate && { endDate }),
                    ...(name && { name }),
                    ...(description && { description }),
                    ...(isCompleted && { isCompleted }),
                    updatedCard,
                  },
                });
              }),
              catchError(() => EMPTY)
            )
        )
      );
    }
  );

  readonly moveCardToBoardEffect = this.effect(
    (event$: Observable<{ value: { id: string }; boardId: string }>) => {
      return event$.pipe(
        withLatestFrom(this.select(state => state.card?.id)),
        switchMap(([{ value, boardId }, currentCardId]) =>
          this._cardService
            .shareCard('Section', `section:${value.id}:0`, currentCardId)
            .pipe(
              tap(updatedCard => {
                this.setUpdatedCard(updatedCard);
                this._socketService.publishEvent({
                  roomId: boardId,
                  eventType: 'move-card',
                  payload: {
                    cardId: value.id,
                    updatedCardId: currentCardId,
                  },
                });
              }),
              catchError(() => EMPTY)
            )
        )
      );
    }
  );
  readonly updateTag = this.effect(
    (
      card$: Observable<{
        tagId: string;
        roleType: string;
        body: { name?: string; color?: string };
        boardId: string;
      }>
    ) => {
      return card$.pipe(
        switchMap(({ tagId, roleType, body, boardId }) =>
          this._cardService.updateTag({ objectId: tagId, roleType, body }).pipe(
            tap(updatedTag => {
              this.setUpdatedTag(updatedTag);
              this._socketService.publishEvent({
                roomId: boardId,
                eventType: 'update-tag',
                payload: updatedTag,
              });
            }),
            catchError(() => EMPTY)
          )
        )
      );
    }
  );
  readonly updateCardMember = this.effect(
    (
      member$: Observable<{
        memberId: string;
        boardId: string;
        email?: string;
        memberName?: string;
      }>
    ) => {
      return member$.pipe(
        withLatestFrom(this.select(state => state.card)),
        switchMap(([member, card]) => {
          if (!card?.id) {
            console.warn('Card ID is missing');
            return EMPTY;
          }

          const memberName =
            member.memberName ||
            card.users?.find(user => user.id === member.memberId)?.name ||
            'Unknown Member';

          const email = member.email || 'No email provided';

          return this._cardService
            .addCardMember(card.id, member.memberId, email, memberName)
            .pipe(
              tap(newCardMember => {
                // Update the card with the new member details
                this.setUpdatedCard({
                  ...card,
                  users: [
                    ...(card.users || []),
                    { id: member.memberId, name: memberName, email },
                  ], // Add member info
                });

                // Send real-time update through socket
                this._socketService.publishEvent({
                  roomId: member.boardId,
                  eventType: 'member',
                  payload: {
                    cardId: card.id,
                    updatedCard: {
                      ...newCardMember,
                      memberName, // Include member name explicitly
                      email, // Include member email
                    },
                    memberId: member.memberId,
                    memberName, // Explicitly send name
                    email, // Explicitly send email
                  },
                });
              }),
              catchError(error => {
                console.error('Error updating card member:', error);
                return EMPTY;
              })
            );
        })
      );
    }
  );

  readonly updateCardResponsible = this.effect(
    (
      payload$: Observable<{
        responsible: UserModel[];
        cardId: string;
      }>
    ) => {
      return payload$.pipe(
        switchMap(({ responsible, cardId }) =>
          this._cardService
            .assignCardResponsible(responsible[0].id as string, cardId)
            .pipe(
              tap(updatedCard => {
                this.setUpdatedCard(updatedCard);
              }),
              catchError(() => EMPTY)
            )
        )
      );
    }
  );
  readonly assignTagToCard = this.effect(
    (
      payload$: Observable<{
        tagId: string;
        tagColor: string;
        tagName: string;
        boardId: string;
      }>
    ) => {
      return payload$.pipe(
        withLatestFrom(this.select(state => state.card?.id)),
        switchMap(([{ tagId, tagColor, tagName, boardId }, cardId]) =>
          this._cardService.assignedTagToCard(tagId, cardId as string).pipe(
            tap(() => {
              const tag = { id: tagId, color: tagColor, name: tagName };
              this.setCardNewTag(tag);
              this._socketService.publishEvent({
                roomId: boardId,
                eventType: 'assign-tag-to-card',
                payload: tag,
              });
            }),
            catchError(() => EMPTY)
          )
        )
      );
    }
  );
  readonly addCardAttachment = this.effect(
    (
      payload$: Observable<{
        file: any;
        boardId: string; // Ensure boardId is passed with payload
        attachmentDetails: {
          createdAt: string;
          size: string;
          documentType: string;
        };
      }>
    ) => {
      return payload$.pipe(
        withLatestFrom(this.select(state => state.card?.id)), // Get the latest card ID from the state
        switchMap(([{ file, boardId, attachmentDetails }, cardId]) =>
          this._cardService
            .addCardAttachment(cardId as string, file, attachmentDetails) // Call service to add attachment
            .pipe(
              tap(file => {
                // Handle the updated file and set the new attachment
                this.setCardNewAttachement(file.createdChildObject);

                // Publish the event to notify other parts of the application
                this._socketService.publishEvent({
                  roomId: boardId, // Use the boardId passed in the payload
                  eventType: 'add-attachment', // Event type for adding attachment
                  payload: {
                    fileName: file.createdChildObject.fileName, // Include the file details
                    fileSize: file.createdChildObject.size,
                    createdAt: file.createdChildObject.createdAt,
                    documentType: attachmentDetails.documentType, // Include document type
                    cardId, // Send the cardId in the event payload
                  },
                });
              }),
              catchError(err => {
                console.error('Error adding attachment:', err); // Log errors for debugging
                return EMPTY; // Return an empty observable on error
              })
            )
        )
      );
    }
  );

  readonly deleteCardAttachment = this.effect((id$: Observable<string>) => {
    return id$.pipe(
      switchMap(id => {
        return this._objectService.deleteObject('document', id).pipe(
          tap(() => {
            this.removeAttachment(id);
          }),
          catchError(() => {
            return EMPTY;
          })
        );
      })
    );
  });
  readonly removeCardTag = this.effect<{
    tagId: string;
    cardId: string;
    boardId: string;
  }>(trigger$ =>
    trigger$.pipe(
      switchMap(({ tagId, cardId, boardId }) =>
        this._objectService
          .unassignedTag(
            {
              leftRoleId: tagId,
              relation: 'is_assigned_to',
              rightRoleId: cardId,
            },
            'Card'
          )
          .pipe(
            tapResponse(
              () => {
                this.updateCardTag(tagId);
                this._socketService.publishEvent({
                  roomId: boardId,
                  eventType: 'remove-tag',
                  payload: { tagId, cardId },
                });
              },
              error => {
                console.error('Failed to remove tag:', error);
              }
            )
          )
      )
    )
  );
  // Effect for create a new checklist
  readonly addChecklist = this.effect(
    (
      checklistInfo$: Observable<{
        checklistName: string;
        boardId: string;
      }>
    ) => {
      return checklistInfo$.pipe(
        withLatestFrom(this.select(state => state.card?.id)),
        switchMap(
          ([checklistInfo, cardId]: [
            { checklistName: string; boardId: string },
            string,
          ]) =>
            this._cardService
              .createChecklist(checklistInfo.checklistName, cardId)
              .pipe(
                tap({
                  next: response => {
                    const checklist = response.createdChildObject;
                    this.addNewChecklist(checklist);
                    this._socketService.publishEvent({
                      roomId: checklistInfo.boardId,
                      eventType: 'add-checklist',
                      payload: checklist,
                    });
                  },
                }),
                catchError(() => EMPTY)
              )
        )
      );
    }
  );
  readonly addChecklistItem = this.effect(
    (
      checklistItemInfo$: Observable<{
        name: string;
        checklistId: string;
        checked: boolean;
        boardId: string;
      }>
    ) => {
      return checklistItemInfo$.pipe(
        switchMap(({ name, checklistId, checked, boardId }) =>
          this._cardService
            .createChecklistItem(name, checked, checklistId)
            .pipe(
              tap({
                next: response => {
                  const checklistItem = response.createdChildObject;
                  this.addNewChecklistItem({
                    checklistItem,
                    checklistId,
                    checked,
                  });
                  this._socketService.publishEvent({
                    roomId: boardId,
                    eventType: 'add-Checklist-item',
                    payload: { checklistItem, checklistId, checked },
                  });
                },
              }),
              catchError(error => {
                console.error('Error creating checklist item:', error);
                return EMPTY;
              })
            )
        )
      );
    }
  );
  readonly addCardSubTask = this.effect(
    (subTaskData$: Observable<{ taskName: string; boardId: string }>) => {
      return subTaskData$.pipe(
        withLatestFrom(this.select(state => state.card?.id)),
        switchMap(
          ([{ taskName, boardId }, cardId]: [
            { taskName: string; boardId: string },
            string,
          ]) => {
            return this._cardService.createCardSubTask(cardId, taskName).pipe(
              tap({
                next: response => {
                  const subtask = response.createdChildObject;
                  this.addNewSubTask(subtask);
                  this._socketService.publishEvent({
                    roomId: boardId,
                    eventType: 'add-SubCard',
                    payload: subtask,
                  });
                },
              }),
              catchError(() => EMPTY)
            );
          }
        )
      );
    }
  );
  // create comment
  readonly addCardComment = this.effect(
    (
      commentInfo$: Observable<{
        parentRoleType: string;
        parentId: string;
        name: string;
        time: string;
        description: string;
        relation: string;
        boardId: string;
      }>
    ) => {
      return commentInfo$.pipe(
        withLatestFrom(
          // Logged user
          this._store.select(AuthStoreSelectors.selectLoggedUser)
        ),
        switchMap(([commentInfo, loggedUser]) =>
          this._cardService
            .createComment(
              commentInfo.name,
              commentInfo.time,
              commentInfo.parentRoleType,
              commentInfo.parentId,
              commentInfo.relation
            )
            .pipe(
              tap({
                next: response => {
                  const comment = response.createdChildObject;
                  this.addNewComment({
                    comment,
                    parentRoleType: commentInfo.parentRoleType,
                    parentId: commentInfo.parentId,
                    users: loggedUser ? [loggedUser] : [],
                  });
                  this._socketService.publishEvent({
                    roomId: commentInfo.boardId,
                    eventType: 'add-comment',
                    payload: {
                      comment,
                      parentRoleType: commentInfo.parentRoleType,
                      parentId: commentInfo.parentId,
                      users: loggedUser ? [loggedUser] : [],
                    },
                  });
                },
              }),
              catchError(() => EMPTY)
            )
        )
      );
    }
  );
  // update comment
  readonly updateComment = this.effect(
    (
      commentInfo$: Observable<{
        commentId: string;
        content: string;
        description: string;
        lastUpdated: string;
        parentRoleType: string;
        boardId: string;
      }>
    ) => {
      return commentInfo$.pipe(
        withLatestFrom(this._store.select(AuthStoreSelectors.selectLoggedUser)),
        switchMap(([commentInfo]) =>
          this._cardService
            .updateComment(
              commentInfo.content,
              commentInfo.lastUpdated,
              commentInfo.commentId
            )
            .pipe(
              tap(updatedComment => {
                this.setUpdatedComment({
                  updatedComment: updatedComment,
                  parentRolType: commentInfo.parentRoleType,
                });
                this._socketService.publishEvent({
                  roomId: commentInfo.boardId,
                  eventType: 'update-comment',
                  payload: {
                    updatedComment: updatedComment,
                    parentRolType: commentInfo.parentRoleType,
                  },
                });
              }),
              catchError(() => EMPTY)
            )
        )
      );
    }
  );
  //**************************************************Updaters***************************************
  // ---------------------set the coming card---------------------
  readonly setCard = this.updater(
    (
      state,
      payload: {
        card: CardModelResponse;
        isParent: boolean;
        parentName?: string;
        parentId?: string;
      }
    ) => {
      const updatedCard = { ...payload.card };
      return {
        ...state,
        card: updatedCard,
        isParent: payload.isParent,
        parentName: payload.parentName,
        parentId: payload.parentId,
      };
    }
  );
  readonly setUpdatedCard = this.updater((state, updatedCard: any) => ({
    ...state,
    card: {
      ...(state.card || {}), // Ensure state.card is an object
      ...updatedCard, // Merge all properties from the updated card
      members: updatedCard?.members ?? state.card?.members ?? [], // Safely handle members
    },
  }));

  readonly setUpdatedTag = this.updater(
    (state, updatedTag: CreatedChildObject) => {
      if (!state.card) return state;
      const tag = state.card.tag.map(tag => {
        if (tag.id === updatedTag.id) {
          return {
            ...tag,
            name: updatedTag.name,
            color: updatedTag.value ? updatedTag.value.color : tag.color,
          };
        }
        return tag;
      });
      return {
        ...state,
        card: {
          ...state.card,
          tag: tag,
        },
      };
    }
  );
  readonly setCardNewResponsible = this.updater(
    (state, payload: { responsible: UserModel }) => {
      if (state.card.responsible[0].id === payload.responsible.id) {
        return {
          ...state,
          card: {
            ...state.card,
            responsible: [],
          },
        };
      } else {
        return {
          ...state,
          card: {
            ...state.card,
            responsible: [payload.responsible],
          },
        };
      }
    }
  );
  readonly updateCardTag = this.updater<string>((state, tagId) => ({
    ...state,
    card: {
      ...state.card,
      tag: state.card.tag.filter((tag: any) => tag.id !== tagId),
    },
  }));
  readonly setCardNewTag = this.updater((state, tag: Tag) => ({
    ...state,
    card: {
      ...state.card,
      tag: [...state.card.tag, tag],
    },
  }));
  readonly setCardNewAttachement = this.updater(
    (state, newDocument: { id: string; name: string; value: any }) => ({
      ...state,
      card: {
        ...state.card,
        document: [...state.card.document, newDocument],
      },
    })
  );
  // ---------------------set the new checklist created ---------------------
  readonly addNewChecklist = this.updater(
    (state, checklist: CreatedChildObject) => ({
      ...state,
      card: {
        ...state.card,
        checklist: [
          ...(state.card?.checklist || []),
          {
            id: checklist.id,
            name: checklist.name,
            description: checklist.description,
          },
        ],
      },
    })
  );
  readonly removeChecklist = this.updater((state, id: string) => ({
    ...state,
    card: {
      ...state.card,
      checklist: state.card?.checklist?.filter(
        checklist => checklist.id !== id
      ),
    },
  }));
  readonly removeAttachment = this.updater((state, id: string) => ({
    ...state,
    card: {
      ...state.card,
      document: state.card?.document?.filter(document => document.id !== id),
    },
  }));
  // ---------------------set the new checklistItem created ---------------------
  readonly addNewChecklistItem = this.updater(
    (
      state,
      payload: {
        checklistItem: CreatedChildObject;
        checklistId: string;
        checked: boolean;
      }
    ) => {
      const updatedChecklist = (state.card?.checklist || []).map(checklist => {
        if (checklist.id === payload.checklistId) {
          return {
            ...checklist,
            checkListItem: [
              ...(checklist.checkListItem || []),
              {
                id: payload.checklistItem.id,
                name: payload.checklistItem.name,
                description: payload.checklistItem.description,
                checked: payload.checked,
              },
            ],
          };
        }
        return checklist;
      });
      const updatedState = {
        ...state,
        card: {
          ...state.card,
          checklist: updatedChecklist,
        },
      };
      return updatedState;
    }
  );
  readonly addNewSubTask = this.updater(
    (state, createdSubTask: CreatedChildObject) => {
      const newSubCard: SubCardModelResponse = {
        id: createdSubTask.id,
        name: createdSubTask.name,
        startDate: createdSubTask.startDate,
        endDate: createdSubTask.endDate,
        priority: createdSubTask.priority || '',
        isCompleted: createdSubTask.value.isCompleted,
        responsible: createdSubTask.value.responsible || [],
        comment: 0,
        document: 0,
      };
      return {
        ...state,
        card: {
          ...(state.card || {}),
          card: [...(state.card?.card || []), newSubCard],
        },
      };
    }
  );
  //---------------------set the updated comment ---------------------
  readonly setUpdatedComment = this.updater(
    (
      state,
      data: { updatedComment: CreatedChildObject; parentRolType: string }
    ) => {
      const newUpdatedComment: any = {
        id: data.updatedComment.id,
        name: data.updatedComment.name || '',
        description: data.updatedComment.description || '',
        lastUpdated: data.updatedComment.value.lastUpdated,
      };
      if (data.parentRolType === 'card') {
        const updatedComments = (state.card?.comment || []).map(comment => {
          if (comment.id === newUpdatedComment.id) {
            return {
              ...comment,
              name: newUpdatedComment.name,
              description: newUpdatedComment.description,
              lastUpdated: newUpdatedComment.lastUpdated as string,
            };
          }
          return comment;
        });
        return {
          ...state,
          card: {
            ...state.card,
            comment: updatedComments,
          },
        };
      } else {
        const updatedComments = (state.card?.comment || []).map(
          parentComment => {
            if (parentComment.comment) {
              const updatedChildComments = (parentComment.comment || []).map(
                childComment => {
                  if (childComment.id === newUpdatedComment.id) {
                    return {
                      ...childComment,
                      name: newUpdatedComment.name,
                      description: newUpdatedComment.description,
                      lastUpdated: newUpdatedComment.lastUpdated as string,
                    };
                  }
                  return childComment;
                }
              );
              return {
                ...parentComment,
                comment: updatedChildComments,
              };
            }
            return parentComment;
          }
        );
        return {
          ...state,
          card: {
            ...state.card,
            comment: updatedComments,
          },
        };
      }
    }
  );
  //---------------------set the new comment created ---------------------
  readonly addNewComment = this.updater(
    (
      state,
      payload: {
        comment: CreatedChildObject;
        parentRoleType: string;
        parentId: string;
        users: AuthUser[];
      }
    ) => {
      const updatedState = { ...state };
      if (payload.parentRoleType === 'Card') {
        updatedState.card = {
          ...updatedState.card,
          comment: [
            ...(updatedState.card?.comment || []),
            {
              id: payload.comment.id,
              name: payload.comment.name,
              time: payload.comment.value.time,
              users: payload.users as AuthUser[],
              lastUpdated: payload.comment.value.lastUpdated || '',
              comment: [],
            },
          ],
        };
      } else if (payload.parentRoleType === 'Comment') {
        updatedState.card = {
          ...updatedState.card,
          comment: (updatedState.card?.comment || []).map(comment => {
            if (comment.id === payload.parentId) {
              return {
                ...comment,
                comment: [
                  ...(comment.comment || []),
                  {
                    id: payload.comment.id,
                    name: payload.comment.name,
                    description: payload.comment.description,
                    time: payload.comment.value.time,
                    lastUpdated: '',
                    users: payload.users as AuthUser[],
                  },
                ],
              };
            }
            return comment;
          }),
        };
      }
      return updatedState;
    }
  );
  readonly deleteCommentt = this.effect(
    (deleteInfo$: Observable<{ id: string; boardId: string }>) => {
      return deleteInfo$.pipe(
        switchMap(({ id, boardId }) =>
          this._objectService.deleteObject('comment', id).pipe(
            tap(() => {
              this.deleteComment(id);
              this._socketService.publishEvent({
                roomId: boardId,
                eventType: 'delete-comment',
                payload: { id },
              });
            }),
            catchError(() => EMPTY)
          )
        )
      );
    }
  );
  readonly deleteComment = this.updater((state, id: string) => ({
    ...state,
    card: {
      ...state.card,
      comment: state.card?.comment?.filter(comment => comment.id !== id),
    },
  }));
  readonly deleteSubCommentt = this.effect(
    (deleteInfo$: Observable<{ id: string; boardId: string }>) => {
      return deleteInfo$.pipe(
        switchMap(({ id, boardId }) =>
          this._objectService.deleteObject('comment', id).pipe(
            tap(() => {
              this.deleteSubComment(id);
              this._socketService.publishEvent({
                roomId: boardId,
                eventType: 'delete-subcomment',
                payload: { id },
              });
            }),
            catchError(() => EMPTY)
          )
        )
      );
    }
  );
  readonly deleteSubComment = this.updater((state, id: string) => {
    const updatedComments =
      state.card?.comment?.map(comment => ({
        ...comment,
        comment: comment.comment.filter(
          nestedComment => nestedComment.id !== id
        ),
      })) || [];
    return {
      ...state,
      card: {
        ...state.card,
        comment: updatedComments,
      },
    };
  });
  readonly editChecklistTitle = this.effect(
    (
      checklistInfo$: Observable<{
        checklistId: string;
        newTitle: string;
        boardId: string;
      }>
    ) => {
      return checklistInfo$.pipe(
        switchMap(({ checklistId, newTitle, boardId }) =>
          this._objectService
            .updateObject('Checklist', checklistId, { name: newTitle })
            .pipe(
              tap({
                next: () => {
                  this.updateChecklistTitle({ checklistId, newTitle });
                  this._socketService.publishEvent({
                    roomId: boardId,
                    eventType: 'update-checklist-title',
                    payload: { checklistId, newTitle },
                  });
                },
              }),
              catchError(() => EMPTY)
            )
        )
      );
    }
  );
  // Updater to update the checklist title in the state
  readonly updateChecklistTitle = this.updater(
    (
      state,
      { checklistId, newTitle }: { checklistId: string; newTitle: string }
    ) => ({
      ...state,
      card: {
        ...state.card,
        checklist: state.card?.checklist?.map(checklist =>
          checklist.id === checklistId
            ? { ...checklist, name: newTitle }
            : checklist
        ),
      },
    })
  );
  readonly updateChecklistItem = this.effect(
    (
      itemInfo$: Observable<{
        itemId: string;
        data: { name: string; checked: boolean };
        boardId: string;
      }>
    ) => {
      return itemInfo$.pipe(
        switchMap(({ itemId, data, boardId }) =>
          this._objectService.updateObject('CheckListItem', itemId, data).pipe(
            tap({
              next: () => {
                this.updateChecklistItemState({ itemId, data });
                this._socketService.publishEvent({
                  roomId: boardId,
                  eventType: 'update-checklistitem-title',
                  payload: { itemId, data },
                });
              },
            }),
            catchError(() => EMPTY)
          )
        )
      );
    }
  );
  readonly updateChecklistItemState = this.updater(
    (
      state,
      params: { itemId: string; data: { name: string; checked: boolean } }
    ) => {
      const { itemId, data } = params;
      const updatedChecklists = (state.card?.checklist || []).map(
        checklist => ({
          ...checklist,
          checkListItem: (checklist.checkListItem || []).map(item =>
            item.id === itemId
              ? { ...item, name: data.name, checked: data.checked }
              : item
          ),
        })
      );
      return {
        ...state,
        card: {
          ...state.card,
          checklist: updatedChecklists,
        },
      };
    }
  );
  readonly toggleCardShareStatus = this.effect(
    (
      event$: Observable<{ value: { id: string }; boardId: string }> // Listen for event with boardId
    ) => {
      return event$.pipe(
        withLatestFrom(this.select(state => state.card?.id)), // Get cardId from state
        switchMap(([event, cardId]) =>
          this._cardService
            .shareCard(
              'Section', // The role type for the parent
              `section:${event.value.id}:0`, // The section ID to share
              cardId as string // The current card ID
            )
            .pipe(
              tap(response => {
                // Once card is shared, update the card state if necessary
                this.setUpdatedCard(response);

                // Publish event to the room using boardId as roomId
                this._socketService.publishEvent({
                  roomId: event.boardId, // Assign boardId to roomId
                  eventType: 'card-shared',
                  payload: {
                    cardId,
                    sharedCardId: event.value.id, // The ID of the card that was shared
                    response, // The response from shareCard service
                  },
                });
              }),
              catchError(() => EMPTY) // Handle any errors silently
            )
        )
      );
    }
  );

  readonly readEvents = this.effect<void>(() =>
    this._socketService.getEvents().pipe(
      tapResponse(
        (event: ReceivedEvent) => {
          switch (event.eventType) {
            case 'assign-tag-to-card':
              this.setCardNewTag(event.payload as Tag);
              break;
            // update-card-name
            case 'update-card-details':
              this.setUpdatedCard(event.payload as UpdatePayload);
              break;
            // update-tag
            case 'update-tag':
              this.setUpdatedTag(event.payload as CreatedChildObject);
              break;
            // assign-tag-ToCard
            case 'assign-tag-ToCard':
              this.setCardNewTag(event.payload as Tag);
              break;
            // Add-subCard
            case 'add-SubCard':
              this.addNewSubTask(event.payload as CreatedChildObject);
              break;
            // Add-comment
            case 'add-comment':
              this.addNewComment(
                event.payload as {
                  comment: CreatedChildObject;
                  parentRoleType: string;
                  parentId: string;
                  users: AuthUser[];
                }
              );
              break;
            // Add-checkList-Item
            case 'add-Checklist-item':
              this.addNewChecklistItem(
                event.payload as {
                  checklistItem: CreatedChildObject;
                  checklistId: string;
                  checked: boolean;
                }
              );
              break;
            // add-checklist
            case 'add-checklist':
              this.addNewChecklist(event.payload as CreatedChildObject);
              break;
            // remove-checklist
            case 'remove-checklist':
              this.removeChecklist((event.payload as { id: string }).id);
              break;
            // remove-tag
            case 'remove-tag':
              this.updateCardTag((event.payload as { tagId: string }).tagId);
              break;
            // update-checklist-title
            case 'update-checklist-title':
              this.updateChecklistTitle(
                event.payload as { checklistId: string; newTitle: string }
              );
              break;
            case 'update-checklistitem-title':
              this.updateChecklistItemState(
                event.payload as {
                  itemId: string;
                  data: { name: string; checked: boolean };
                }
              );
              break;
            case 'update-comment':
              this.setUpdatedComment(
                event.payload as {
                  updatedComment: CreatedChildObject;
                  parentRolType: string;
                }
              );
              break;
            case 'delete-comment':
              this.deleteComment((event.payload as { id: string }).id);
              break;
            case 'delete-subcomment':
              this.deleteSubComment((event.payload as { id: string }).id);
              break;
            case 'member':
              this.setUpdatedCard(event.payload as { updatedCard: any });
              break;
            case 'move-card':
              this.setUpdatedCard(event.payload as { updatedCard: any });
              break;
            case 'add-attachment':
              this.setCardNewAttachement(
                event.payload as { id: string; name: string; value: any }
              );
              break;
            default:
              console.warn(
                `Received unexpected event : ${JSON.stringify(event)}`
              );
          }
        },
        error => console.error(error)
      )
    )
  );
}
