import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateResponse } from '@excelway/models/api/create-response';
import { QueryModel } from '@excelway/models/api/query';
import { UpdatePayload } from '@excelway/models/api/update-payload';
import { CardModelResponse } from '@excelway/models/board/card.model';
import { ApiService } from '@excelway/services/api.service';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CardService {
  constructor(
    private _httpClient: HttpClient,
    private _apiService: ApiService
  ) {}

  assignCardResponsible(
    responsibleId: string,
    cardId: string
  ): Observable<any> {
    return this._httpClient.patch<any>(
      `${environment.backendUrl}/v1/assign/card/${cardId}/responsible`,
      { responsibleId: responsibleId }, // Send the payload as an object
      { withCredentials: true }
    );
  }
  addCardMember(
    cardId: string,
    memberId: string,
    email?: string,
    name?: string
  ): Observable<UpdatePayload> {
    const payload = {
      roleType: 'Card',
      roleTypeId: cardId,
      memberId,
      email,
      name, // Include name in the payload
    };

    return this._httpClient.patch<UpdatePayload>(
      `${environment.backendUrl}/v1/toggle/${payload.roleType}/${payload.roleTypeId}/member`,
      payload,
      { withCredentials: true }
    );
  }

  getCardProperties(cardId: string): Observable<any> {
    return this._httpClient.get<any>(
      `${environment.backendUrl}/v1/card_selection/${cardId}`,
      { withCredentials: true }
    );
  }

  getCard(id: string): Observable<CardModelResponse> {
    const query: QueryModel = {
      roleType: 'Card',
      id: id,
      fields: [
        'id',
        'name',
        'description',
        'startDate',
        'endDate',
        'priority',
        'isCompleted',
        'cost',
        'duration',
      ],
      isRead: true,
      relations: [
        {
          relation: 'members',
          fields: ['id', 'name', 'email', 'avatar'],
        },
        {
          relation: 'document',
          fields: ['id', 'name', 'createdAt', 'size', 'documentType'],
        },
        {
          relation: 'users',
          fields: ['id', 'name'],
        },
        {
          relation: 'responsible',
          fields: ['id', 'name', 'email', 'avatar'],
        },
        {
          relation: 'tag',
          fields: ['id', 'name', 'color'],
        },
        {
          relation: 'checklist',
          fields: ['id', 'name', 'itemsChecked', 'index', 'description'],
          relations: [
            {
              relation: 'checkListItem',
              fields: ['id', 'name', 'checked', 'index', 'description'],
            },
          ],
        },
        {
          relation: 'card',
          fields: [
            'id',
            'name',
            'startDate',
            'endDate',
            'priority',
            'isCompleted',
          ],
          relations: [
            {
              relation: 'responsible',
              fields: ['id', 'name', 'email', 'avatar'],
            },
            {
              relation: 'comment',
              count: true,
            },
            {
              relation: 'document',
              count: true,
            },
          ],
        },
        {
          relation: 'comment',
          fields: ['id', 'name', 'time', 'lastUpdated'],
          relations: [
            {
              relation: 'users',
              fields: ['id', 'name', 'email'],
            },
            {
              relation: 'comment',
              fields: ['id', 'name', 'time', 'lastUpdated'],
              relations: [
                {
                  relation: 'users',
                  fields: ['id', 'name', 'email'],
                },
              ],
            },
          ],
        },
      ],
    };
    return this._apiService.getObject<CardModelResponse>(query);
  }

  // update card fields
  updateCard(payload: UpdatePayload): Observable<any> {
    return this._apiService.updateObject(payload);
  }

  createCardSubTask(
    parentId: string,
    name: string
  ): Observable<CreateResponse> {
    const payload = {
      parentRoleType: 'Card',
      parentId,
      relationship: 'is_part_of',
      roleType: 'Card',
      body: {
        name,
        priority: 'NA',
      },
    };
    return this._apiService.createObject(payload);
  }
  //create card checklist
  createChecklist(
    checklistName: string,
    parentId: string
  ): Observable<CreateResponse> {
    const payload = {
      parentRoleType: 'Card',
      parentId,
      relationship: 'is_component_of',
      roleType: 'Checklist',
      body: {
        name: checklistName,
      },
    };
    return this._apiService.createObject(payload);
  }

  //create  checklist item
  createChecklistItem(
    checklistItemName: string,
    checked: boolean,
    parentId: string
  ): Observable<CreateResponse> {
    const payload = {
      parentRoleType: 'Checklist',
      parentId,
      relationship: 'is_component_of',
      roleType: 'CheckListItem',
      body: {
        name: checklistItemName,
        checked: checked,
      },
    };
    return this._apiService.createObject(payload);
  }
  //create comment
  createComment(
    commentName: string,
    commentTime: string,
    parentRoleType: string,
    parentId: string,
    relation: string
  ): Observable<CreateResponse> {
    const payload = {
      parentRoleType: parentRoleType,
      parentId: parentId,
      relationship: relation,
      roleType: 'Comment',
      body: {
        name: commentName,
        time: commentTime,
      },
    };
    return this._apiService.createObject(payload);
  }
  //update commet
  updateComment(
    commetName: string,
    lastUpdated: string,
    commentId: string
  ): Observable<any> {
    const payload = {
      objectId: commentId,
      roleType: 'Comment',
      body: {
        name: commetName,
        lastUpdated: lastUpdated,
      },
    };
    return this._apiService.updateObject(payload);
  }

  // asign tag to card
  assignedTagToCard(tagId: string, cardId: string): Observable<any> {
    const ROLE_TYPE_PARENT = 'Card';
    return this._apiService.assignedTagToObject(
      tagId,
      ROLE_TYPE_PARENT,
      cardId
    );
  }
  addCardAttachment(
    cardId: string,
    file: any,
    attachmentDetails: {
      createdAt: string;
      size: string;
      documentType: string;
    }
  ): Observable<any> {
    return this._apiService.addAttachment(
      'Card',
      cardId,
      file,
      attachmentDetails
    );
  }
  updateTag(payload: UpdatePayload): Observable<any> {
    return this._apiService.updateObject(payload);
  }

  shareCard(
    newRoleTypeParent: string,
    newRoleTypeParentId: string,
    roleTypeToShareId: string
  ): Observable<any> {
    const roleTypeToShare = 'Card';
    return this._apiService.shareObject(
      newRoleTypeParent,
      newRoleTypeParentId,
      roleTypeToShare,
      roleTypeToShareId
    );
  }

  getCardParents(cardId: string): Observable<any> {
    const url = `${environment.backendUrl}/v2/parents/card/${cardId}`;
    return this._httpClient.get<any>(url, { withCredentials: true }).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  updateCardDate(cardId: string, dateData: any): Observable<UpdatePayload> {
    const payload = {
      roleType: 'Card',
      roleTypeId: cardId,
      dateData,
    };

    return this._httpClient
      .put<UpdatePayload>(
        `${environment.backendUrl}/v1/${payload.roleType}/${payload.roleTypeId}`,
        payload.dateData,
        { withCredentials: true }
      )
      .pipe(
        catchError(error => {
          console.error('Update failed:', error);
          return throwError(() => error);
        })
      );
  }
}
