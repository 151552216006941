import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  private scrollSubject = new Subject<void>();
  scrollRequest$ = this.scrollSubject.asObservable();

  requestScroll(): void {
    this.scrollSubject.next();
  }
}
